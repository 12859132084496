import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  sidebarShow?: string;
  asideShow?: boolean;
  darkMode?: boolean;
  authenticated?: boolean;
  pivo?: any;
  orcamento?: any;
  historico?: any;
  pivoHeader?: any;
  action?: string;
  codeNew?: any;
  approveEnable?: boolean;
  credentials?: {
    token: any;
    user: any;
  };
}

const initialState: initialStateProps = {
  sidebarShow: "responsive",
  asideShow: false,
  darkMode: false,
  authenticated: false,
  pivo: {},
  orcamento: null,
  historico: null,
  pivoHeader: {},
  action: "",
  codeNew: null,
  approveEnable: false,
  credentials: {
    token: null,
    user: null,
  },
};

const setSlice = createSlice({
  name: "set",
  initialState,
  reducers: {
    set(state, action) {
      return { ...state, ...action };
    },
  },
});

export const { set } = setSlice.actions;
export default setSlice.reducer;
