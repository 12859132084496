import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "src/app/store";
import { FIELDS } from "./constants";
console.log(`env`, process.env.REACT_APP_API_URL);
export const baseApi = createApi({
  reducerPath: "baseApi",
  tagTypes: [FIELDS.REVENDAS],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const session = state.set.credentials;

      if (!session.token) {
        return headers;
      }
      console.log(`session`, session);
      headers.set("Authorization", `Bearer ${session.token.token}`);

      return headers;
    },
  }),
  endpoints(builder) {
    return {};
  },
});
